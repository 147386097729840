<template>
  <div>
    <a-table
        ref="table"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.id"
        :pagination="false"

    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <!--        eslint-disable-next-line vue/no-unused-vars -->
      <template slot="operation" slot-scope="text, record">

        <a @click="showReply(record.id)">回复</a>

      </template>

    </a-table>

    <a-pagination style="float: right;margin-top: 15px;"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />

    <a-modal v-model="reply_modal" @ok="reply" @cancel="()=>{this.rValue='';this.reply_modal=false}">
      <a-textarea style="margin-top: 15px" v-model="rValue" :auto-size="{ minRows: 16, maxRows: 100 }"/>
    </a-modal>
  </div>
</template>

<script>
const key = '123'
export default {
  name: "AdviceList",
  data() {
    return {
      curr: 1,

      reply_modal: false,
      reply_id: '',
      rValue: '',
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          key: 'uid',
          align: "center",
        },
        {
          title: '内容',
          dataIndex: 'content',
          key: 'content',
          align: "center",
          ellipsis: "true",
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",

        },
        {
          title: '最后更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          align: "center",
        },

        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        },
      ],
      data: [],
      returnBack: [],
    }
  },
  methods: {
    showReply(v) {
      this.reply_id = v
      this.reply_modal = true
    },
    reply() {
      this.$message.loading({content: '邮件推送中', key, duration: 0})
      this.$axios.post('/Admin/Api/Proposals/replySuggestion', {
        id: this.reply_id,
        reply: this.rValue
      }).then(value => {
        this.$message.loading({content: value.data.msg, key, duration: 2})
        this.reply_id = ''
        this.rValue = ''
        this.reply_modal = false
      }).catch(err => {
        this.$message.success({content: err.msg, key, duration: 2})
        this.reply_id = ''
        this.rValue = ''
        this.reply_modal = false
      })
      this.reply_id = ''
      this.rValue = ''
      this.reply_modal = false
    }

  },
  watch: {
    curr: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Proposals/getProposalsList', {
          page: n,
          pageCount: 12,
          state: 0
        })).data.data)
        this.data = this.returnBack.list
      }
    },
  },
  async created() {
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Proposals/getProposalsList', {
      page: 1,
      pageCount: 12,
      state: 0
    })).data.data)
    this.data = this.returnBack.list
  }
}
</script>

<style scoped>

</style>